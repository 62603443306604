import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import {SendMailService} from '../../service/send-mail.service';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.css']
})
export class TopComponent implements OnInit {

  announce = '';

  constructor(private sendMail: SendMailService) { }

  ngOnInit() {
    $('#show-menu').on('click', function() {
      if ($('#top-menu .navbar-nav').css('display') === 'none') {
        $('#top-menu .navbar-nav').slideDown();
        $(this).html('<i class="fa fa-close"></i>');
      } else {
        $('#top-menu .navbar-nav').slideUp();
        $(this).html('<i class="fa fa-bars"></i>');
      }
    });
  }

}
