import { Component, OnInit } from '@angular/core';
import {ScriptService} from '../../service/script.service';

@Component({
  selector: 'app-appoinment',
  templateUrl: './appoinment.component.html',
  styleUrls: ['./appoinment.component.css']
})
export class AppoinmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    const windowWidth = $(window).width();
    let minHeightVar = 600;

    if (windowWidth <= 768) {
      minHeightVar = 700;
    }

    ScriptService.fancybox2Update();

    // $.validate({
    //   scrollToTopOnError : false
    // });
  }

}
