import { Injectable, NgZone } from '@angular/core';

declare var document: any;

@Injectable({
  providedIn: 'root'
})
export class ScriptService {

  /**
   * Name of the global google recaptcha script
   */
  protected readonly windowGrecaptcha = 'grecaptcha';

  /**
   * Name of the global callback
   */
  protected readonly windowOnLoadCallbackProperty = 'ngx_captcha_onload_callback';

  protected readonly globalDomain: string = 'recaptcha.net';

  protected readonly defaultDomain: string = 'google.com';

  constructor(
    protected zone: NgZone,
  ) {
  }

  static fancybox1Update() {
    const windowWidth = $(window).width();
    let minHeightVar = 690;

    if (windowWidth > 768) {
      minHeightVar = 660;
    }

    $('.fancybox-1').fancybox({
      tpl:  {
        closeBtn : '<a title="Close" class="fancybox-close-btn" href="javascript:;"><i class="fa fa-close"></i></a>',
      },
      minHeight: minHeightVar,
    });
  }

  static fancybox2Update() {
    const windowWidth = $(window).width();
    if (windowWidth > 768) {
      $('.fancybox-2').fancybox({
        tpl: {
          closeBtn: '<a title="Close" class="fancybox-close-btn" href="javascript:;"><i class="fa fa-close"></i></a>',
        },
        minHeight: 660,
      });
    } else {
      $('.fancybox-2').fancybox({
        tpl: {
          closeBtn: '<a title="Close" class="fancybox-close-btn" href="javascript:;"><i class="fa fa-close"></i></a>',
        },
        minHeight: 760,
      });
    }
  }

  registerCaptchaScript(useGlobalDomain: boolean, siteKey: string, onLoad: (grecaptcha: any) => void): void {
    if (this.grecaptchaScriptLoaded()) {
      // recaptcha script is already loaded
      // just call the callback
      this.zone.run(() => {
        onLoad(window[this.windowGrecaptcha]);
      });

      return;
    }

    // we need to patch the callback through global variable, otherwise callback is not accessible
    window[this.windowOnLoadCallbackProperty] = (() => this.zone.run(
      onLoad.bind(this, window[this.windowGrecaptcha])
    )) as any;
    // note: https://github.com/Enngage/ngx-captcha/issues/2

    // prepare script elem
    const scriptElem = document.createElement('script');
    scriptElem.innerHTML = '';
    scriptElem.src = this.getCaptchaScriptUrl(useGlobalDomain, siteKey);
    scriptElem.async = true;
    scriptElem.defer = true;

    // add script to header
    document.getElementsByTagName('head')[0].appendChild(scriptElem);
  }

  cleanup(): void {
    window[this.windowOnLoadCallbackProperty] = undefined;
    window[this.windowGrecaptcha] = undefined;
  }

  /**
   * Indicates if google recaptcha script is available and ready to be used
   */
  private grecaptchaScriptLoaded(): boolean {
    return window[this.windowOnLoadCallbackProperty] && window[this.windowGrecaptcha];
  }

  /**
   * Url to google api script
   */
  private getCaptchaScriptUrl(useGlobalDomain: boolean, siteKey: string): string {
    const domain = useGlobalDomain ? this.globalDomain : this.defaultDomain;

    // tslint:disable-next-line:max-line-length
    return `https://www.${domain}/recaptcha/api.js?render=${siteKey}`;
  }
}
