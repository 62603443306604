import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BsDatepickerModule } from 'ngx-bootstrap';

import { ExpoComponent } from './component/expo/expo.component';
import { TopComponent } from './component/top/top.component';
import { HeaderSliderComponent } from './component/header-slider/header-slider.component';
import { ServicesComponent } from './component/services/services.component';
import { AboutClinicComponent } from './component/about-clinic/about-clinic.component';
import { DoctorsComponent } from './component/doctors/doctors.component';
import { TipsComponent } from './component/tips/tips.component';
import { FooterComponent } from './component/footer/footer.component';
import { BreadcrumbsComponent } from './component/breadcrumbs/breadcrumbs.component';
import { AppoinmentComponent } from './component/appoinment/appoinment.component';

import { BookForm1Component } from './component/book-form1/book-form1.component';
import { HomeComponent } from './pages/home/home.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { PricesPageComponent } from './pages/prices-page/prices-page.component';
import { HourValidatorDirective } from './hour-validator.directive';
import { GalleryPageComponent } from './pages/gallery/gallery-page.component';

@NgModule({
  declarations: [
    AppComponent,
    TopComponent,
    HeaderSliderComponent,
    ExpoComponent,
    ServicesComponent,
    AboutClinicComponent,
    DoctorsComponent,
    TipsComponent,
    FooterComponent,
    HomeComponent,
    PageNotFoundComponent,
    BreadcrumbsComponent,
    ContactPageComponent,
    PricesPageComponent,
    GalleryPageComponent,
    AppoinmentComponent,
    BookForm1Component,
    HourValidatorDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
