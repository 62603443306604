import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import {SendMailService} from '../../service/send-mail.service';
import {ReCaptchaV3Service} from '../../service/recaptcha_v3.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-contact-page',
  animations: [
    trigger('showHide', [
      state('visible', style({
        // display: 'block',
        opacity: 1,
      })),
      state('hidden', style({
        // display: 'none',
        opacity: 0,
      })),
      transition('hidden => visible', [
        animate('1s')
      ]),
      transition('visible => hidden', [
        animate('0.5s')
      ])
    ])
  ],
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.css']
})
export class ContactPageComponent implements OnInit {
  map: google.maps.Map;
  submitted = false;
  backEndResponse = '';
  loading = false;

  constructor(private sendMail: SendMailService, private reCaptchaV3Service: ReCaptchaV3Service) { }

  ngOnInit() {
    let patternColor = 'fff';
    $('.bg-pattern').each( (index, element) => {
      patternColor = $(element).data('bgcolor');
      $(element).attr('style', 'background-color: #' + patternColor + ' !important');
    });

    const windowWidth = $(window).width();
    const containerW = $('.container').width();
    const mapInfoL = (windowWidth - containerW) / 2;

    $('#map-info').css('left', mapInfoL + 'px');

    const myLatLng = new google.maps.LatLng(44.479549, 26.095621);

    const mapOptions = {
      center: myLatLng,
      zoom: 17,
      scrollwheel: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(document.getElementById('map'), mapOptions);

    const marker = new google.maps.Marker({
      position: myLatLng,
      map: this.map,
      icon: 'assets/images/marker.png'
    });
  }

  contactFormSubmit(f: NgForm) {
    this.submitted = true;
    this.backEndResponse = '';
    this.loading = true;

    if (f.form.valid) {
      this.reCaptchaV3Service.execute('6Lc-yp8UAAAAAEean5n3GsFQu9w9CEaBQvQkl9If', 'contact_form', (token) => {
        this.sendMail.sendMail(f.form.value, token).subscribe(
          data => {
            // console.log(data);
            f.reset();
            this.submitted = false;
            this.backEndResponse = 'success';
            this.loading = false;
          },
          error => {
            // console.log(error.error);
            this.backEndResponse = 'error';
            this.loading = false;
          }
        );
      }, {
        useGlobalDomain: false,
        justLoadScript: false
      });

    } else {
      this.loading = false;
    }

    // console.log(f.form.valid);
    // console.log(f.form.value);
  }

}
