import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery-page',
  templateUrl: './gallery-page.component.html',
  styleUrls: ['./gallery-page.component.css']
})
export class GalleryPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    let patternColor = 'fff';
    $('.bg-pattern').each( (index, element) => {
      patternColor = $(element).data('bgcolor');
      $(element).attr('style', 'background-color: #' + patternColor + ' !important');
    });

    let grid = $('#isotope-items');
    grid.imagesLoaded( function() {
      grid.isotope({
        itemSelector: '.isotope-item',
        layoutMode: 'fitRows'
      });
    });

    $('#isotope-filter').on( 'click', 'li>a', function() {
      let filterValue = $( this ).attr('data-filter');
      grid.isotope({ filter: filterValue });
      $('#isotope-filter>li>a').removeClass('active');
      $( this ).addClass('active');
      return false;
    });

    $('.fancybox-1').fancybox({
      tpl:  {
        closeBtn : '<a title="Close" class="fancybox-close-btn" href="javascript:;"><i class="fa fa-close"></i></a>',
      }
    });
  }

}
