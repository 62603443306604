import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SendMailService {

  constructor(private http: HttpClient) { }



  sendMail(formValues: object, token: string) {
    // console.log(formValues);
    // console.log('token: ', token);

    const body = {formData: formValues, token: token};

    return this.http.post('https://us-central1-happy-clinic.cloudfunctions.net/sendMail', body, httpOptions);
  }
}
