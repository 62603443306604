import {Component, NgZone, OnInit} from '@angular/core';
import * as jQuery from 'jquery';
import {ScriptService} from './service/script.service';
import {ReCaptchaV3Service} from './service/recaptcha_v3.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'Happy Clinic - cabinet stomatologic pipera';

  constructor(private reCaptcha: ReCaptchaV3Service, private zone: NgZone) {}

  ngOnInit(): void {

    // this.reCapthaScripLoaderService.registerCaptchaScript(false)
    //
    this.reCaptcha.execute('6Lc-yp8UAAAAAEean5n3GsFQu9w9CEaBQvQkl9If', 'global', (token) => {
      console.log('This is your token: ', token);
    }, {
      useGlobalDomain: false,
      justLoadScript: true
    });

    // $(window).resize(function() {
    //     $.fancybox.update();
    // });

    /*=======================================
      Datepicker init
    =========================================*/

    // $('.datepicker-f').datetimepicker({
    //   format: 'DD/MM/YYYY',
    //   icons: {
    //     up: 'fa fa-angle-up',
    //     down: 'fa fa-angle-down',
    //     previous: 'fa fa-angle-left',
    //     next: 'fa fa-angle-right',
    //   }
    // });

    /*=======================================
        Timepicker init
    =========================================*/

    // $('.timepicker-f').datetimepicker({
    //   format: 'HH:mm',
    //   icons: {
    //     up: 'fa fa-angle-up',
    //     down: 'fa fa-angle-down',
    //     previous: 'fa fa-angle-left',
    //     next: 'fa fa-angle-right',
    //   }
    // });

    $('<div id="smartposition"></div>').insertBefore('#top-menu');
    this.smartPosition('#smartposition');

    $('.carousel-next').on('click', function() {
      $(this).parent().find('.owl-carousel').trigger('owl.next');
      return false;
    });

    $('.carousel-prev').on('click', function() {
      $(this).parent().find('.owl-carousel').trigger('owl.prev');
      return false;
    });
  }

  smartPosition(obj) {
    jQuery(window).scroll(() => {
      // Detect if content is being scroll offscreen.
      if ( (document.documentElement.scrollTop || document.body.scrollTop) >= jQuery(obj).offset().top - (0)) {
        jQuery('body').addClass('smartposition');
      } else {
        jQuery('body').removeClass('smartposition');
      }
    });
  }
}
