import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Data} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent implements OnInit {
  breadcrumbs: Array<object>;


  constructor(route: ActivatedRoute) {
     const data: Observable<Array<object>> = route.data.pipe(map(d => d.breadcrumbs));

     data.subscribe(val => this.breadcrumbs = val);
  }

  ngOnInit() {}

}
