import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
  selector: '[appHourValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: HourValidatorDirective, multi: true}]
})
export class HourValidatorDirective implements Validator {
  @Input('appHourValidator') hourText: string;

  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {
    const regularExpresion = new RegExp('^[0-9]{1,2}:?[0-9]{0,2}$');
    return regularExpresion.test(control.value) ? null : {error: true};
  }

}
