import { Component, OnInit } from '@angular/core';
import {ScriptService} from '../../service/script.service';

@Component({
  selector: 'app-header-slider',
  templateUrl: './header-slider.component.html',
  styleUrls: ['./header-slider.component.css']
})
export class HeaderSliderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $('#slider').sliderPro({
      width: '100%',
      height: 550,
      fade: true,
      arrows: true,
      buttons: true,
      waitForLayers: true,
      autoplay: true,
      autoplayDelay: 4000,
      autoplayOnHover: 'pause',
      breakpoints: {
        768: {
          height: 450,
          arrows: false,
        }
      }
    });

    ScriptService.fancybox2Update();
  }

}
