import {Component, ElementRef, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {SendMailService} from '../../service/send-mail.service';
import {ReCaptchaV3Service} from '../../service/recaptcha_v3.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-book-form1',
  animations: [
    trigger('showHide', [
      state('visible', style({
        // display: 'block',
        opacity: 1,
      })),
      state('hidden', style({
        // display: 'none',
        opacity: 0,
      })),
      transition('hidden => visible', [
        animate('1s')
      ]),
      transition('visible => hidden', [
        animate('0.5s')
      ])
    ])
  ],
  templateUrl: './book-form1.component.html',
  styleUrls: ['./book-form1.component.css']
})
export class BookForm1Component implements OnInit {

  submitted = false;
  backEndResponse = '';
  loading = false;
  minDate = new Date();
  maxDate = new Date();

  doctors = ['default', 'Andra Constantinescu', 'Mariana Constantinescu', 'Paula Constaninescu'];

  constructor(private sendMail: SendMailService, private reCaptchaV3Service: ReCaptchaV3Service) {}

  ngOnInit() {
    this.maxDate.setDate(this.minDate.getDate() + 14);
  }

  bookFormSubmit(f: NgForm) {
    this.submitted = true;
    this.backEndResponse = '';
    this.loading = true;

    if (f.form.valid) {
      this.reCaptchaV3Service.execute('6Lc-yp8UAAAAAEean5n3GsFQu9w9CEaBQvQkl9If', 'book_form', (token) => {
        this.sendMail.sendMail(f.form.value, token).subscribe(
          data => {
            console.log('success');
            console.log(data);
            f.reset();
            f.form.value['form-doctor'] = '';
            this.submitted = false;
            this.backEndResponse = 'success';
            this.loading = false;
          },
          error => {
            console.log('error');
            console.log(error.error);
            this.backEndResponse = 'error';
            this.loading = false;
          }
        );
      }, {
        useGlobalDomain: false,
        justLoadScript: false
      });

    } else {
      this.loading = false;
    }

    // console.log(f.form.valid);
    // console.log(f.form.value);
  }
}
