import { Component, OnInit } from '@angular/core';
import * as jQuery from 'jquery';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $('<div id="smartposition"></div>').insertBefore('#top-menu');
    this.smartPosition('#smartposition');

    $('.carousel-next').on('click', function() {
      $(this).parent().find('.owl-carousel').trigger('owl.next');
      return false;
    });

    $('.carousel-prev').on('click', function() {
      $(this).parent().find('.owl-carousel').trigger('owl.prev');
      return false;
    });
  }

  smartPosition(obj) {
    jQuery(window).scroll(() => {
      // Detect if content is being scroll offscreen.
      if ( (document.documentElement.scrollTop || document.body.scrollTop) >= jQuery(obj).offset().top - (0)) {
        jQuery('body').addClass('smartposition');
      } else {
        jQuery('body').removeClass('smartposition');
      }
    });
  }

}
