import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    let patternColor = 'fff';
    $('.bg-pattern').each( (index, element) => {
      patternColor = $(element).data('bgcolor');
      $(element).attr('style', 'background-color: #' + patternColor + ' !important');
    });
  }

}
