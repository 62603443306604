import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AlertModule } from 'ngx-bootstrap';
import { HomeComponent } from './pages/home/home.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { PricesPageComponent } from './pages/prices-page/prices-page.component';
import { GalleryPageComponent } from './pages/gallery/gallery-page.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: 'preturi',
    component: PricesPageComponent,
    data: {
      breadcrumbs: [
        {name: 'Preturi', link: ''},
      ]
    }
  },
  {
    path: 'galerie',
    component: GalleryPageComponent,
    data:
      {
        breadcrumbs: [
          {name: 'Galerie', link: ''},
        ]
      }
  },
  {
    path: 'contact',
    component: ContactPageComponent,
    data:
      {
        breadcrumbs: [
          {name: 'Contact', link: ''},
        ]
      }
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data:
      {
        breadcrumbs: [
          {name: 'Pagina inexistenta', link: ''},
        ]
      }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: false}), AlertModule.forRoot()],
  exports: [RouterModule]
})
export class AppRoutingModule { }
