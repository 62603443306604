import {Injectable, NgZone} from '@angular/core';

import {ScriptService} from './script.service';

@Injectable({
  providedIn: 'root'
})
export class ReCaptchaV3Service {

  constructor(
    protected scriptService: ScriptService,
    protected zone: NgZone
  ) {
  }

  /**
   * Executes reCaptcha v3 with given action and passes token via callback. You need to verify
   * this callback in your backend to get meaningful results.
   *
   * For more information see https://developers.google.com/recaptcha/docs/v3
   *
   * @param siteKey Site key found in your google admin panel
   * @param actionName Action to log
   * @param callback callback function
   * @param config if it uses google or recaptcha domain
   */
  execute(siteKey: string, actionName: string, callback: (token: string) => void, config?: {
    useGlobalDomain: boolean,
    justLoadScript: boolean
  }): void {

    const useGlobalDomain = config && config.useGlobalDomain;
    const justLoadScript = config && config.justLoadScript;

    this.scriptService.registerCaptchaScript(useGlobalDomain, siteKey, (grecaptcha) => {
      this.zone.runOutsideAngular(() => {
        if (!justLoadScript) {
          grecaptcha.ready(() => {
            grecaptcha.execute(siteKey, {
              action: actionName
            }).then((token) => {
              this.zone.run(() => callback(token));
            });
          });
        }
      });
    });
  }
}
