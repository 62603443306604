import { Component, OnInit } from '@angular/core';

import {} from 'googlemaps';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  map: google.maps.Map;

  constructor() { }

  ngOnInit() {
    const myLatLng = new google.maps.LatLng(44.479549, 26.095621);

    const mapOptions = {
      center: myLatLng,
      zoom: 17,
      scrollwheel: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
      },
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
      },
      mapTypeControl: false,
    };

    this.map = new google.maps.Map(document.getElementById('footer-item-4'), mapOptions);

    const marker = new google.maps.Marker({
      position: myLatLng,
      map: this.map,
      icon: 'assets/images/marker.png'
    });

    let windowWidth = $(window).width();
    let f3Width = $('#footer-item-3').outerWidth();
    let f4Width = windowWidth / 2 - f3Width;
    $('#footer-item-4').outerWidth(f4Width + 'px');

    $(window).on('resize', () => {
      windowWidth = $(window).width();
      f3Width = $('#footer-item-3').outerWidth();
      f4Width = windowWidth / 2 - f3Width;
      $('#footer-item-4').outerWidth(f4Width + 'px');
    });
  }

}
